import { post, get, } from "@/request/index";
//import qs from "qs";

// 注册
export function sysRegister(options) {
  return post("/client/sys/register", options);
}
// 登录
export function sysLogin(options) {
  return post("/client/sys/login", options);
}
// 获取验证码
export function sysSend(options) {
  return post("/client/sys/send", options);
}


// 设置新密码
export function syssettingPassword(options) {
  return post("/client/sys/setting_password", options);
}
// 个人信息
export function userInfo(options) {
  return get("/client/user/info", options);
}

export function userSettingPassword(options) {
  return post("/client/user/setting_password", options);
}
export function homeMessage(options) {
  return get("/client/home/message", options);
}
export function homeNotice(options) {
  return get("/client/home/notice", options);
}
export function homeActivity(options) {
  return get("/client/home/activity", options);
}
export function homeMessageReadall(options) {
  return post("/client/home/message/read_all", options);
}
export function homeMessageReadcancelall(options) {
  return post("/client/home/message/read_cancel_all", options);
}
export function messageDetail(options, type) {
  return get("/client/home/message/detail/" + options, type);
}
export function depositChannel(options) {
  return get("/client/deposit/channel", options);
}

//各种记录
export function getRecord(options,type) {
  switch (type.type) {
    case 0:
      return post("/client/deposit/record", options);
    case 1:
      return post("/client/withdraw/record", options);
    case 2:
      return post("/client/game/record", options);
      case 3:
      return post("/client/dividend/record", options);
      case 4:
      return post("/client/rebate/record", options);
      case 5:
        return post("/client/adjust/record", options);
        case 6:
        return post("/client/game/transfer_record", options);
    default:
      break;
  }
}

//游戏记录
export function gameRecord(options) {
  return post("/client/game/record", options);
}
//存款记录
export function depositRecord(options) {
  return post("/client/deposit/record", options);
}
//提款记录
export function withdrawRecord(options) {
  return post("/client/withdraw/record", options);
}
//钱包列表
export function walletList(options) {
  return get("/client/wallet/list", options);
}
//添加钱包
export function walletAdd(options) {
  return post("/client/wallet/add", options);
}
//删除卡片
export function walletDelect(id,password) {
  return get(`/client/wallet/delete/${id}`,password);
}

//
export function homePlatform(options) {
  return get("/client/home/platform", options);
}

//提现通道
export function withdrawChannel(options) {
  return get("/client/withdraw/channel", options);
}

// 取款
export function withdrawApply(options) {
  return post("/client/withdraw/apply", options);
}
// 福利列表
export function welfareRecord(options) {
  return post("/client/welfare/record", options);
}
// 领取福利
export function welfareReceive(options) {
  return get(`/client/welfare/receive/${options}`,);
}
// 提款限制
export function withdrawConfig(options) {
  return get("/client/withdraw/config", options);
}
// 合营
export function homeInit(options) {
  return get("/client/home/init", options);
}
// 邀请
export function welfareInfo(options) {
  return get("/client/welfare/info", options);
}
// 设置邮箱
export function userSettingEmail(options) {
  return post("/client/user/setting_email", options);
}

// 退出登录
export function syslogout(options) {
  return get("/client/sys/logout", options);
}

// 设置信息
export function userSettingInfo(options) {
  return post("/client/user/setting_info", options);
}
// 无邮箱验证码
export function userSendEmail(options) {
  return get("/client/user/send_email", options);
}

// 场馆列表
export function platformList(options) {
  return get("/client/platform/list", options);
}
// 电子游戏列表
export function platformSlot(options) {
  return get("/client/platform/game", options);
}
// 添加收藏
export function platformSlotAddFavorite(options) {
  return get("/client/platform/game/add_favorite", options);
}
export function pdgameLaunch(options) {
  return get("/pdgame/launch", options);
}
//游戏搜索
export function platformSearch(options) {
  return get("/client/platform/search", options);
}
//banner
export function homeBanner(options) {
  return get("/client/home/banner", options);
}

export function paymentCreate(options) {
  return post("/payment/create", options);
}

export function userVerifyCode(options) {
  return get("/client/user/verify_code", options);
}

export function gameReport(options) {
  return get("/client/game/report", options);
}

// 签到
export function qiandao(options) {
  return get("/client/activity/treasure_box/sign",options);
}
// 签到签到日历
// {
//  calendar:[签到日历]
//  phase1:"第一期中奖内容"
//  phase2:"第二期中奖内容"
//     phase3:"第三期中奖内容"
//     result:"三期都签满的终极大奖"
// }

export function calendar(options) {
  return get("/client/activity/treasure_box/calendar",options);
}

// 修改密码
// export function updatePwd(options) {
//   return put("?" + qs.stringify(options), "");
// }


export function depositFeedback(options) {
  return post("/client/deposit/feedback", options);
}

export function activityList(options) {
  return get("/client/activity/list",options);
}
export function homeVip(options) {
  return get("/client/home/vip",options);
}

export function activityRebateConfig(options) {
  return get("/client/activity/rebate/config",options);
}
export function welfareType(options) {
  return get("/client/welfare/type",options);
}

export function platformLogin(options) {
  return get("/client/platform/login",options);
}

export function platformWallet(options) {
  return get("/client/platform/wallet",options);
}

export function platformWallet_balance(options) {
  return get("/client/platform/wallet_balance",options);
}


export function platformBalance(options) {
  return get("/client/platform/balance",options);
}

export function platformRecovery(options) {
  return get("/client/platform/recovery",options);
}

export function platformTransfer(options) {
  return post("/client/platform/transfer",options);
}

export function userInvitation(options) {
  return get("/client/user/invitation",options);
}

export function platformChange_auto_transfer(options) {
  return get("/client/platform/change_auto_transfer",options);
}

export function homeImportant_notification(options) {
  return get("/client/home/important_notification",options);
}

// 我的页面专属
export function userHome(options) {
  return get("/client/user/home",options);
}

export function platformEeturn_recovery(options) {
  return get("/client/platform/return_recovery",options);
}

export function platformSearch_hot(options) {
  return get("/client/platform/search_hot",options);
}

//修改头像

export function userSetting_headimg(options) {
  return post("/client/user/setting_headimg",options);
}







