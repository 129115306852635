<template>
  <div id="app" class="appOut">
   
    <router-view/> 
  </div>
</template>

<script>


export default {
  components: {
    // HelloWorld

  },
  data(){
    return{
    }
  },

  methods:{
    
  },
  mounted(){
    
  },
  computed: {
    
  }
}
</script>

<style lang="less">
html{
  // overscroll-behavior: none;
  
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100dvh;
  // background: #101B1F;
  position: fixed;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 2;
  
}

body{
  background: #202C37;
  overflow: hidden;
  position: relative;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
*{
  margin:0;
  border:0;
  box-sizing: border-box;
}
.contents{
  // background: radial-gradient(128.3% 54.62% at 131.6% -1.79%, #02568E 6%, #18222A 61.18%, #18222A 100%);
          background-repeat: no-repeat;
          background-size: cover;
          min-height: 100vh;
}
.loginContent{
  // background-image: url('@/assets/login/background.svg');
  background: #101B1F;
  background-repeat: no-repeat;
  background-size: cover;
          min-height: 100dvh;
          height: 100dvh;
}

.loadingPop{
      background-color:transparent!important;
      width:380px;
      height:280px;
    }

    @keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -2rem, 0);
  }
}
.bouncing-loader {
  display: flex;
  justify-content: center;
}
.bouncing-loader > div {
  width: 0.8rem;
  height: 0.8rem;
  margin: 3rem 0.2rem;
  //background: #8385aa;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;

  img{
    width: 100%;
    height: 100%;
  }
}
.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}
.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
img{
  pointer-events: none;
}
.van-popover__arrow{
  border-bottom-color: rgba(82, 89, 119, 1)!important;
}
.van-popover__content{
  background-color: rgba(82, 89, 119, 1);
  border-radius: 4px!important;
}
</style>
